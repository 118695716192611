import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';
import Head from '../shared/Head';
import booksStyles from './books.module.scss';

const Books = () => {
    return (
        <Layout>
            <Head title={`Carti`}/>
            <DoubleLayout>
                <h1 className={booksStyles.pagetitle}>Listă de cărți recomandate pentru începători</h1>
                <p>Mai jos găsești o lista de cărți pe care le recomand începătorilor în acest domeniu și vreau să fac 
                    asta pentru că astea sunt o parte din cărțile care pe mine m-au ajutat la început și care m-au transformat
                    într-un FE developer cât de cât competent.
                </p>
                <p>Dacă citești acest blog de pe un laptop cel mai probabil că poți vedea aceste cărți în sidebarul din stânga
                    al acestui website. Daca ești pe mobil sau pe un ecran mai miculuț cel mai probabil nu le poți vedea așa că le 
                    poți găsi mai jos alături de o mica descriere pentru fiecare.
                </p>
                <h3>HTML & CSS - Design an build websites - John Ducket</h3>
                <p>Pentru începători asta cred că e cea mai tare carte despre web development. Spun asta pentru că prezintă
                    foarte simplu într-un mod visual cele mai importante elemente HTML astfel încât sunt foarte simplu de înțeles.
                </p>
                <p>După ce termină cu elementele HTML autorul trece la partea de CSS. Începe cu elementele de bază și aici pentru ca mai 
                    apoi să explice cum să construișeti diferite componente pe care le găsești în mod normal într-o pagina web...de exemplu
                    meniuri, modale, efecte, animații css și multe alte lucruri interesante.
                </p>
                <h3>JavaScript & jQuery - interactive front end development - John Ducket</h3>
                <p>Următorul pas după ce ai învățat HTML și CSS ar fi să înveți JavaScript ca să adaugi interactivitate aplicației tale.
                </p>
                <p>Cartea asta este fix ce ai nevoie. Explică tot așa într-un mod visual și clar limbajul de programare JavaScript, cum poți 
                    să îl folosești pe o pagina web și de asemenea care sunt lucrurile pe care le poți crea. Vorbește despre funcții, obiecte
                    variabile, liste și alte lucruri de genul. 
                </p>
                <p>Mai departe cartea vorbește despre libraria jQuery. jQuery este o colecție de funcții scrise în JavaScript funcții pe care le 
                    poți folosi pentru a manipula mai ușor și mai intuitiv o pagina web. Ce e super fain la jQeury e faptul că felul în care
                    e construită îi ajutaa foarte mult pe designeri și pe cei începători și mai obișnuiți cu CSS să deprindă mai ușor abilități
                    în JavaScript. 
                </p>
                <h3>Fullstack React - The complete guide to ReactJs and Friends</h3>
                <p>O carte despre ReactJS care te învață pe lăngă libraria discutată și alte lucruri care sunt considerate ca fiind practici bune
                    ale dezvoltării aplicaațiilor web. Vorbește despre React, Redux, Rute, Webpack, Formulare și multe alte lucruri. Îți recomand 
                    să o citești dacă ai ajuns cât de cât la un nivel confortabil cu dezvoltarea de pagini web și vrei să treci la nivelul următor.
                </p>

                <h3>Clean Code - Robert C Martin</h3>
                <p>Asta este o carte pe care ar trebui să o citească toată lumea din aceasta industrie. Nu e neapărat o carte pentru începători dar îi 
                    poate ajuta pe începători să scrie cod de calitate.
                </p>
                <p>Una din cele mai mari probleme pe care le întîmpină aceasta industrie este faptul că după ce un anumit produs a fost creat și
                    eliberat pe piața ( un site web mare și complicat, a aplicație mobilă, o aplicație bancară) urmează mentenanța și adaugarea
                    progresivă de noi funcționalități în funcție de nevoile clientului iar dacă felul în care a fost scrisă aplicația este prost atunci 
                    mentenanța și adaugarea de noi funcționalități pe produsul respectiv va fi foarte foarte greoaie și scumpă. Dacă nimeni nu înțelege
                    ce se întîmplă acolo în cod atunci cum poate adăga chestii noi.
                </p>
                <p>Cartea prezintă niște principii care te ajută să scrii code de calitate care după ce  tu vei fi plecat de pe proiect nu va fi 
                    o problemă pentru cei care au venit să citească și să înțeleagă ce ai făcut tu pe acolo.
                </p>
                <p>Cam asta este tot ce am avut de adaugat în aceasta parte a blogului. Probabil că vor apărea modificări la această listă pe parcursul acestei
                    aventuri cu blogul...dar vom vedea.
                </p>
                <p>Carțile le poți vedea în sidebar daca te uiți de pe un calculator sau le poți căuta pe net dacă te uiți de pe un telefon
                    mobil.
                </p>
                
            </DoubleLayout>
        </Layout>
    );
}
export default Books;